import { easingTransitions } from '@lib/utils/animate'
import { Text, Picture } from '@components/ui'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import { useState, useMemo } from 'react'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import SceneCard from '../SceneCard'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'

function Installer({ currentSmallBoxContent, s, pageType }) {
  const { setRelativeLink } = useRelativeLink()

  const [collectionHover, setCollectionHover] = useState(0)

  const { installerList } = currentSmallBoxContent

  const currentInstallerHover = useMemo(() => {
    return installerList && installerList?.[collectionHover]
  }, [installerList, collectionHover])

  const [mobileIndex, setMbIndex] = useState(0)

  return (
    <motion.div
      key={`${currentSmallBoxContent?.mode}-${currentSmallBoxContent?.text}`}
      className={cn(s?.content, s?.gridContent, 'min-l:py-[40px]')}
      initial={{
        opacity: 0,
        x: -30,
      }}
      animate={{
        opacity: 1,
        x: 0,
        transition: {
          ease: easingTransitions?.easeInQuart,
          duration: 0.3,
          delay: 0.15,
        },
      }}
      exit={{
        opacity: 0,
        x: -30,
        transition: {
          ease: easingTransitions?.easeInQuart,
          duration: 0.3,
        },
      }}
    >
      <div className={cn(s.selectList, 'l:hidden')}>
        <ul className="w-full">
          {installerList?.map((item, index) => {
            return (
              <li
                key={`collectionsLi-${item?.name}${index}`}
                className={cn(
                  s.option,
                  'w-full border-b border-[#E2E2E2] pb-[30px] text-[16px] font-[600] last:border-b-0 last:pb-0 last:pt-[30px]',
                  {
                    [s.active]: collectionHover === index,
                  }
                )}
              >
                <button
                  onMouseEnter={() => {
                    setCollectionHover(index)
                  }}
                >
                  <span className="font-[600]">{item?.name}</span>
                  {/* <ChevronRightIcon className={s.iconArrow} /> */}
                </button>
              </li>
            )
          })}
        </ul>
      </div>
      <div
        className={cn(
          s.optionContent,
          'custom-scroll h-full max-h-[700px] !overflow-y-auto pb-[30px]  l:hidden xxl:max-h-[470px]'
        )}
      >
        <AnimatePresence mode="layout">
          <div className="flex w-full flex-wrap justify-start gap-[12px]">
            {currentInstallerHover?.cardList?.map((product, index) => {
              return (
                <SceneCard
                  pageType={pageType}
                  product={product}
                  index={index}
                  key={`starProducts-${product?.title}-${index}`}
                ></SceneCard>
              )
            })}
          </div>
        </AnimatePresence>
      </div>
      <div className="min-l:hidden">
        <ul className={s.menuMobile}>
          {installerList?.map((collection, index) => {
            return (
              <li
                key={`collectionsMLi-${collection?.name}-${index}`}
                className={cn(s.option, {
                  [s.active]: mobileIndex === index,
                })}
              >
                {installerList?.length > 1 && (
                  <button
                    onClick={() => {
                      if (mobileIndex >= 0 && mobileIndex === index) {
                        setMbIndex(-1)
                      } else {
                        setMbIndex(index)
                      }
                      window.location.hash = `navi_3_${collection?.name
                        ?.toLowerCase()
                        .replace(/\s+/g, '_')}`
                    }}
                  >
                    <span className="text-[14px] font-[500]">
                      {collection?.name}
                    </span>
                    <ChevronRightIcon
                      width={14}
                      className={cn(
                        s.collectionMenuDown,
                        'transition-all',
                        `${
                          mobileIndex === index
                            ? 'rotate-[90deg]'
                            : 'rotate-[0deg]'
                        }`
                      )}
                    />
                  </button>
                )}

                <motion.div
                  className={s.optionContent}
                  animate={mobileIndex === index ? 'show' : 'hidden'}
                  variants={{
                    show: {
                      height: 'auto',
                      transition: {
                        duration: 0.3,
                        ease: easingTransitions?.easeInOutQuart,
                      },
                    },
                    hidden: {
                      height: 0,
                    },
                  }}
                >
                  <div className="w-full">
                    <div className="my-[20px] flex w-full flex-wrap justify-start transition-all duration-300 ease-in-out  l:flex-nowrap l:gap-[12px] l:overflow-x-auto">
                      {collection?.cardList?.map((product, index) => {
                        return (
                          <SceneCard
                            key={`starProducts-${product?.name}-${index}`}
                            index={index}
                            pageType={pageType}
                            product={product}
                          ></SceneCard>
                        )
                      })}
                    </div>
                  </div>
                </motion.div>
              </li>
            )
          })}
        </ul>
      </div>
    </motion.div>
  )
}

export default Installer
