import { motion, AnimatePresence } from 'framer-motion'
import { easingTransitions } from '@lib/utils/animate'
import Button from '@components/control/Button'
import { ChevronRightIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import { appendRefParameter, atobID } from '@lib/utils/tools'
import cn from 'classnames'
import styles from './BottomBar.module.css'
function BottomBar({
  s,
  setRelativeLink,
  currentCollectionHover,
  pageType,
  lang,
  has_custom,
}) {
  return (
    <div className={cn(s.bottomBar, 'mt-[20px] border-t-[1px] border-[#ddd]')}>
      <ul className={s.list}>
        <AnimatePresence mode="popLayout">
          {currentCollectionHover?.custom_others_links?.map((item, index) => {
            return item?.label ? (
              <motion.li
                key={`custom_others_links-${item}-${index}`}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={{
                  initial: {
                    opacity: 0,
                    x: -20,
                  },
                  animate: {
                    opacity: 1,
                    x: 0,
                    transition: {
                      ease: easingTransitions?.easeInOutQuart,
                      duration: 0.2,
                      delay: 0.1 + index * 0.05,
                    },
                  },
                  exit: {
                    opacity: 0,
                    transition: {
                      ease: easingTransitions?.easeInQuart,
                      duration: 0.2,
                    },
                  },
                }}
              >
                <a
                  href={setRelativeLink({
                    link: appendRefParameter({
                      url: item?.to,
                      refValue:
                        pageType === 'charger' ? 'naviMenu' : 'naviMenu_pps',
                    }),
                  })}
                  rel="noopener noreferrer"
                  className={cn(s.linkTo, s.newLinkTo)}
                >
                  {item?.label}
                  <ChevronRightIcon className={s.linkToArrow} />
                </a>
              </motion.li>
            ) : (
              ''
            )
          })}
        </AnimatePresence>
      </ul>

      <a
        href={
          currentCollectionHover?.shopAllLink
            ? setRelativeLink({
                link: currentCollectionHover?.shopAllLink,
              })
            : setRelativeLink({
                link: appendRefParameter({
                  url: has_custom
                    ? currentCollectionHover?.href ||
                      currentCollectionHover?.path
                    : currentCollectionHover?.path,
                  refValue:
                    pageType === 'charger' ? 'naviMenu' : 'naviMenu_pps',
                }),
              })
        }
        className={cn(styles?.solixButtonBorder)}
      >
        <Button squareBorder={true} className="text-linear-div">
          <span className="text-linear">
            {currentCollectionHover?.jumpCollection
              ? currentCollectionHover.jumpCollection
              : lang?.seeAll?.replace(
                  '%collection%',
                  currentCollectionHover?.name
                )}
          </span>
        </Button>
      </a>
    </div>
  )
}

export default BottomBar
