import { ChevronRightIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import cn from 'classnames'
import { useRelativeLink } from '@commerce/product/use-relative-link'
function UlList({
  currentDownBoxContent,
  collectionHover,
  setCollectionHover,
  currentCollectionHover,
  s,
  styles,
  className,
  isClickEvt = false,
}) {
  const custom_links = currentCollectionHover?.custom_links || []
  const { setRelativeLink } = useRelativeLink()
  return (
    <div className={cn(s.selectList, '!col-span-3', className)}>
      <ul className="w-full">
        {currentDownBoxContent?.content?.map((item, index) => {
          return (
            <li
              key={`collectionsLi-${item?.name}${index}`}
              className={cn(s.option, s.newOption, {
                [s.active]: collectionHover === index,
              })}
            >
              <button
                onMouseEnter={() => {
                  if (isClickEvt) return
                  setCollectionHover(index)
                }}
                onClick={() => {
                  isClickEvt && setCollectionHover(index)
                }}
              >
                <span className={cn('font-[600]', s.optionSpan)}>
                  {item?.name}
                </span>
                {collectionHover === index && (
                  <ChevronRightIcon
                    className={cn(s.iconArrow, '!opacity-100')}
                  />
                )}
              </button>
            </li>
          )
        })}
      </ul>
      {custom_links && custom_links?.length > 0 && (
        <>
          <div className="mt-[30px] h-[1px] w-[70%] border-b border-[#E2E2E2]"></div>
          <div className="mt-[30px]">
            {custom_links?.map((item, index) => {
              return (
                item?.link &&
                item?.name && (
                  <a
                    key={index}
                    className="text-[14px] font-[500] text-black hover:text-[#00A9E0]"
                    dangerouslySetInnerHTML={{
                      __html: item?.name,
                    }}
                    href={setRelativeLink({
                      link: item?.link,
                    })}
                  ></a>
                )
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default UlList
