import { motion } from 'framer-motion'
import cn from 'classnames'
import { easingTransitions } from '@lib/utils/animate'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import { appendRefParameter, atobID } from '@lib/utils/tools'
import { Text, Picture } from '@components/ui'
import styles from './SceneBanner/index.module.css'

const Motiona = ({ setRelativeLink, product, url, url2, index, className }) => {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      className={cn(
        'flex w-[240px] flex-shrink-0 flex-col items-center overflow-hidden rounded-[10px] bg-white l:h-[305px] l:w-[252px] min-l:h-[288px]',
        styles?.hoverBox,
        className
      )}
      variants={{
        initial: {
          opacity: 0,
          x: -20,
        },
        animate: {
          opacity: 1,
          x: 0,
          transition: {
            ease: easingTransitions?.easeInOutQuart,
            duration: 0.3,
            delay: 0.2 + index * 0.1,
          },
        },
        exit: {
          opacity: 0,
          transition: {
            ease: easingTransitions?.easeInQuart,
            duration: 0.3,
          },
        },
      }}
    >
      <a
        href={setRelativeLink({
          link: url,
        })}
        className={cn('block h-[55%] w-full overflow-hidden')}
      >
        <Picture
          source={`${product?.img}, ${product?.mbImg || product?.img} 1024`}
          className={cn('h-full ', styles?.hoverImg)}
          imgClassName="h-full object-cover"
        ></Picture>
      </a>

      <div className="flex h-[45%] w-full flex-col justify-between px-[6%] py-[12px]">
        <div>
          <Text
            html={product?.title}
            className="line-clamp-2 text-[16px] font-[600] leading-[18px] text-black"
          ></Text>
          <Text
            html={product?.subtitle}
            className="mt-[6px] text-[14px] font-[500] leading-[16px] text-black"
          ></Text>
        </div>
        <div className="">
          <a
            className="text-[14px] font-[600] !text-[var(--brand-color)] l:mt-[16px]"
            href={setRelativeLink({
              link: url,
            })}
            dangerouslySetInnerHTML={{
              __html: product?.learnMore,
            }}
          ></a>
          {url2 && (
            <a
              className="text-[14px] font-[600] !text-[var(--brand-color)] l:mt-[8px]"
              href={setRelativeLink({
                link: url2,
              })}
              dangerouslySetInnerHTML={{
                __html: product?.learnMore2,
              }}
            ></a>
          )}
        </div>
      </div>
    </motion.div>
  )
}
function SceneCard({ product, index, pageType }) {
  const url = appendRefParameter({
    url: product?.href,
    refValue: pageType === 'charger' ? 'naviMenu' : 'naviMenu_pps',
  })
  const url2 = product?.href2
    ? appendRefParameter({
        url: product?.href2,
        refValue: pageType === 'charger' ? 'naviMenu' : 'naviMenu_pps',
      })
    : null
  const { setRelativeLink } = useRelativeLink()
  return (
    <>
      {product?.cardType === 'large' ? (
        <>
          <div className="relative w-full l:hidden">
            <Picture className="w-full" source={product?.img}></Picture>
            <div className="absolute left-[42px] top-[50%] translate-y-[-50%]">
              {product?.title && (
                <Text
                  className="text-[24px] font-[600] text-white"
                  html={product?.title}
                ></Text>
              )}
              {product?.subtitle && (
                <Text
                  className="mt-[6px] text-[20px] font-[600] text-white"
                  html={product?.subtitle}
                ></Text>
              )}
              <a
                className="mt-[20px] block text-[16px] font-[600] !text-[var(--brand-color)]"
                href={setRelativeLink({
                  link: url,
                })}
                dangerouslySetInnerHTML={{
                  __html: product?.learnMore,
                }}
              ></a>
            </div>
          </div>
          <Motiona
            className="min-l:hidden"
            product={product}
            url={url}
            index={index}
            setRelativeLink={setRelativeLink}
          ></Motiona>
        </>
      ) : product?.cardType === 'large2' ? (
        <>
          <div className="flex h-[260px] w-[996px] items-center overflow-hidden rounded-[10px] l:hidden">
            <div className="flex h-full w-[40%] flex-col justify-center bg-white pl-[32px]">
              {product?.title && (
                <Text
                  className="text-[24px] font-[600] text-black"
                  html={product?.title}
                ></Text>
              )}
              {product?.subtitle && (
                <Text
                  className="mt-[6px] text-[20px] font-[600] text-black"
                  html={product?.subtitle}
                ></Text>
              )}
              <a
                className="mt-[20px] block text-[16px] font-[600] !text-[var(--brand-color)]"
                href={setRelativeLink({
                  link: url,
                })}
                dangerouslySetInnerHTML={{
                  __html: product?.learnMore,
                }}
              ></a>
              {url2 && (
                <a
                  className="mt-[12px] block text-[16px] font-[600] !text-[var(--brand-color)]"
                  href={setRelativeLink({
                    link: url2,
                  })}
                  dangerouslySetInnerHTML={{
                    __html: product?.learnMore2,
                  }}
                ></a>
              )}
            </div>
            <a
              href={setRelativeLink({
                link: url,
              })}
              className="block w-[60%]"
            >
              <Picture
                className="h-full w-full object-cover"
                imgClassName="w-full h-full object-cover"
                source={product?.img}
              ></Picture>
            </a>
          </div>
          <Motiona
            className="min-l:hidden"
            product={product}
            url={url}
            url2={url2}
            index={index}
            setRelativeLink={setRelativeLink}
          ></Motiona>
        </>
      ) : (
        <Motiona
          product={product}
          url={url}
          index={index}
          setRelativeLink={setRelativeLink}
        ></Motiona>
      )}
    </>
  )
}
export default SceneCard
