import { useRouter } from 'next/router'
import cn from 'classnames'
import useCustomer from '@shopify/customer/use-customer'
import Icon from '@components/icons'
import { MULPASS_URL, I18N_STORE_DOMAIN } from '@shopify/const'
import { useEffect, useState } from 'react'
import { Text } from '@components/ui'
import { motion, AnimatePresence } from 'framer-motion'
import { easingTransitions } from '@lib/utils/animate'
import { pageGTMEvent } from '@lib/utils/thirdparty'

const UserNav = ({
  className,
  onMouseEnter,
  onClick,
  data,
  onRegistrations,
  registrationsSettings,
}) => {
  const { locale } = useRouter()
  const { data: customer } = useCustomer()

  return (
    <div className={cn('relative flex h-full items-center', className)}>
      <div
        className={
          'relative flex h-full cursor-pointer items-center text-primary outline-none duration-100 ease-in-out [&:active]:outline-none [&:focus]:outline-none'
        }
      >
        {!!customer ? (
          <a
            href={`${MULPASS_URL}/account/?app=${I18N_STORE_DOMAIN[locale]
              .split('.')
              .shift()}`}
            aria-label="account"
            tag="Auto-10000111"
            className={'flex items-center justify-center rounded-full'}
          >
            <Icon
              iconKey={'user'}
              className="h-[22px] w-[22px] fill-[#000] hover:fill-brand-color l:h-[22px] l:w-[22px] l-xl:h-[18px] l-xl:w-[18px]"
            ></Icon>
          </a>
        ) : data ? (
          <button
            id="userIcon"
            aria-label="account"
            tag="Auto-10000111"
            className={
              'flex items-center justify-center rounded-full [&_svg]:hover:fill-brand-color'
            }
            onMouseEnter={onMouseEnter}
            onClick={onClick}
          >
            <Icon
              iconKey={'user'}
              className="h-[22px] w-[22px] fill-[#000] transition-all l:h-[22px] l:w-[22px] l-xl:h-[18px] l-xl:w-[18px]"
            ></Icon>
          </button>
        ) : (
          <a
            href={
              registrationsSettings
                ? null
                : `${MULPASS_URL}/?app=${I18N_STORE_DOMAIN[locale]
                    .split('.')
                    .shift()}`
            }
            onClick={(e) =>
              registrationsSettings && onRegistrations(e, 'signUp')
            }
            aria-label="account"
            tag="Auto-10000111"
            className={'flex items-center justify-center rounded-full'}
          >
            <Icon
              iconKey={'user'}
              className="h-[22px] w-[22px] fill-[#000] hover:fill-brand-color l:h-[22px] l:w-[22px] l-xl:h-[18px] l-xl:w-[18px]"
            ></Icon>
          </a>
        )}
      </div>
    </div>
  )
}

export default UserNav

export const User = ({
  userPopLeft,
  data,
  preRender,
  onRegistrations,
  registrationsSettings,
}) => {
  const { locale } = useRouter()
  const [origin, setOrigin] = useState(30)

  useEffect(() => {
    setOrigin(
      encodeURIComponent(window.location.origin + window.location.pathname)
    )
  }, [])

  if (!data) return null

  const app = I18N_STORE_DOMAIN[locale].split('.').shift()

  return (
    <AnimatePresence>
      {userPopLeft && (
        <motion.div
          style={{ left: userPopLeft }}
          className="absolute top-[60px] z-[50] flex w-[230px] flex-col items-center border border-[#E2E2E2] bg-white md:right-[24px] md:top-[51px] md:z-[99]"
          initial={{ height: 0 }}
          exit={{ height: 0 }}
          animate={{ height: 'auto' }}
          transition={{
            duration: 0.3,
            delay: 0.1,
            ease: easingTransitions?.easeInOutQuart,
          }}
        >
          <motion.div
            className="flex flex-col items-center justify-center px-[38px] py-[24px] "
            initial={{
              opacity: 0,
              x: -30,
            }}
            animate={{
              opacity: 1,
              x: 0,
              transition: {
                ease: easingTransitions?.easeInQuart,
                duration: 0.3,
                delay: 0.15,
              },
            }}
            exit={{
              opacity: 0,
              x: -30,
              transition: {
                ease: easingTransitions?.easeInQuart,
                duration: 0.3,
              },
            }}
          >
            <Text
              html={data.title}
              className="font-medium"
              variant="paragraph"
            />
            <ul className="ml-[16px] mt-[10px] list-disc">
              {data.points?.map((point) => (
                <li
                  key={point}
                  className="font-medium"
                  dangerouslySetInnerHTML={{ __html: point }}
                ></li>
              ))}
            </ul>
            <a
              href={
                registrationsSettings
                  ? null
                  : `${MULPASS_URL}/?app=${app}&ref=navimenu&source=${origin}${
                      preRender?.userRedirectBack ? `&redirect=${origin}` : ''
                    }`
              }
              tag="Auto-100001111"
              className="mt-[20px] block w-full cursor-pointer rounded-3xl bg-anker-color px-8 py-2 text-center font-medium text-white"
              onClick={(e) => {
                registrationsSettings && onRegistrations(e, 'signIn')
                pageGTMEvent({
                  event: 'uaEvent',
                  eventCategory: 'entry_for_register',
                  eventAction: 'navimenu', //导航栏传navimenu；listing传"Product Detail Page_" + $SKU，其他为空
                  eventLabel: '', //同一个页面有多个注册入口时，可用模块名字来区分不同模块的入口位置（导航栏可忽视）
                  nonInteraction: false,
                })
                pageGTMEvent({
                  event: 'ga4Event',
                  event_name: 'entry_for_register',
                  event_parameters: {
                    page_group: 'navimenu', //导航栏传navimenu；listing传"Product Detail Page_" + $SKU，其他为空
                    button_name: data.signIn, //传按钮文案，如sign in或者sign up
                    position: '', //同一个页面有多个注册入口时，可用模块名字来区分不同模块的入口位置（导航栏可忽视）
                  },
                })
              }}
            >
              {data.signIn}
            </a>
            <a
              href={
                registrationsSettings
                  ? null
                  : `${MULPASS_URL}/?tab=register&app=${app}&ref=navimenu&source=${origin}${
                      preRender?.userRedirectBack ? `&redirect=${origin}` : ''
                    }`
              }
              tag="Auto-100001112"
              className="mt-[6px] block w-full cursor-pointer px-8 py-2 text-center font-medium text-anker-color underline"
              onClick={(e) => {
                registrationsSettings && onRegistrations(e, 'signUp')
                pageGTMEvent({
                  event: 'uaEvent',
                  eventCategory: 'entry_for_register',
                  eventAction: 'navimenu', //导航栏传navimenu；listing传"Product Detail Page_" + $SKU，其他为空
                  eventLabel: '', //同一个页面有多个注册入口时，可用模块名字来区分不同模块的入口位置（导航栏可忽视）
                  nonInteraction: false,
                })
                pageGTMEvent({
                  event: 'ga4Event',
                  event_name: 'entry_for_register',
                  event_parameters: {
                    page_group: 'navimenu', //导航栏传navimenu；listing传"Product Detail Page_" + $SKU，其他为空
                    button_name: data.signUp, //传按钮文案，如sign in或者sign up
                    position: '', //同一个页面有多个注册入口时，可用模块名字来区分不同模块的入口位置（导航栏可忽视）
                  },
                })
              }}
            >
              {data.signUp}
            </a>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
