import cn from 'classnames'
import { useState, useEffect, useMemo, useCallback, memo } from 'react'

const CountDown = ({
  cns = {},
  onlyTime,
  startTime,
  endTime,
  settings,
  daysSill,
  setHideCoupon,
  ...rest
}) => {
  let curTime = new Date().getTime()
  let diff = 0
  let timeTxt = ''
  if (startTime && startTime > curTime) {
    diff = startTime - curTime
    timeTxt = settings?.starts
  } else {
    if (endTime && endTime > curTime) {
      diff = endTime - curTime
      timeTxt = settings?.ends
    }
  }

  const [num, setNum] = useState(Math.floor(diff))

  const formatTitle = useCallback(
    (num) => {
      const days = Math.floor(num / (1000 * 60 * 60 * 24))
      const hours = Math.floor((num % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        .toString()
        .padStart(2, '0')
      const minutes = Math.floor((num % (1000 * 60 * 60)) / (1000 * 60))
        .toString()
        .padStart(2, '0')
      const seconds = Math.floor((num % (1000 * 60)) / 1000)
        .toString()
        .padStart(2, '0')
      if (days >= (daysSill || 7) || num < 0) {
        if (onlyTime) return ''
        return settings?.day_7_txt
      } else if (days === 1) {
        return `${timeTxt} ${days} ${settings?.day} ${hours}:${minutes}:${seconds}`
      } else if (days === 0) {
        return `${timeTxt} ${settings?.today} ${hours}:${minutes}:${seconds}`
      } else {
        return `${timeTxt} ${days} ${settings?.days} ${hours}:${minutes}:${seconds}`
      }
    },
    [onlyTime]
  )

  useEffect(() => {
    let timer = 0
    if (endTime) {
      if (diff > 0 && num !== 0) {
        timer = setTimeout(() => {
          let timeDiff = 0
          if (startTime && startTime > curTime) {
            timeDiff = startTime - new Date().getTime()
            timeTxt = settings?.starts
          } else {
            if (endTime && endTime > curTime) {
              timeDiff = endTime - new Date().getTime()
              timeTxt = settings?.ends
            }
          }

          if (timeDiff <= 1000) {
            clearTimeout(timer)
            setHideCoupon(true)
          } else {
            setNum(timeDiff)
          }
        }, 1000)
      }
    }
    return () => {
      // 组件销毁时，清除定时器
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [num])

  const endTitle = useMemo(() => {
    if (endTime && diff > 0) {
      return formatTitle(num)
    }
    return !!onlyTime ? '' : settings?.day_7_txt
  }, [onlyTime, endTime, diff, formatTitle, num, settings?.day_7_txt])

  return endTitle ? (
    <div
      className={cn(
        'text-left text-[14px] font-semibold leading-[1.2] text-[#ff742d]',
        cns?.couponText
      )}
      {...rest}
    >
      {endTitle}
    </div>
  ) : (
    ''
  )
}

export default memo(CountDown)
