import UlList from '../ulList'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import SolixButton from '@components/control/SolixButton'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import SceneCard from '../SceneCard'
import { useMemo, useState } from 'react'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { easingTransitions } from '@lib/utils/animate'
function SceneBanner({
  currentDownBoxContent,
  s,
  collectionHover,
  setCollectionHover,
  currentCollectionHover,
  pageType,
  shopCommon,
}) {
  const { setRelativeLink } = useRelativeLink()
  const [mobileIndex, setMbIndex] = useState(0)
  const hideShowUlList = useMemo(() => {
    return (
      currentDownBoxContent?.content?.length <= 1 &&
      currentDownBoxContent?.hideShowUlList
    )
  }, [currentDownBoxContent])
  return (
    <>
      {!hideShowUlList && (
        <UlList
          className="l:hidden"
          currentDownBoxContent={currentDownBoxContent}
          collectionHover={collectionHover}
          setCollectionHover={setCollectionHover}
          s={s}
        ></UlList>
      )}

      <div
        className={cn(
          s.optionContent,
          'custom-scroll !col-span-11 h-full max-h-[700px] !overflow-y-auto pb-[30px]  l:hidden xxl:max-h-[560px]',
          `${hideShowUlList ? '!col-span-14' : ''}`
        )}
      >
        <AnimatePresence mode="layout">
          <div className="flex w-full flex-wrap justify-start gap-[12px]">
            {currentCollectionHover?.bannerList?.map((product, index) => {
              return (
                <SceneCard
                  pageType={pageType}
                  product={product}
                  index={index}
                  key={`starProducts-${product?.title}-${index}`}
                ></SceneCard>
              )
            })}
          </div>
          {currentDownBoxContent?.view_all_products && (
            <div className="mt-[25px]">
              <SolixButton
                variant="link"
                href={setRelativeLink({
                  link: currentCollectionHover?.href,
                })}
              >
                {currentDownBoxContent?.view_all_products}
              </SolixButton>
            </div>
          )}
        </AnimatePresence>
      </div>
      <div className="min-l:hidden">
        <ul className={s.menuMobile}>
          {currentDownBoxContent?.content?.map((collection, index) => {
            return (
              <li
                key={`collectionsMLi-${collection?.name}-${index}`}
                className={cn(s.option, 'pl-[5px]', {
                  [s.active]: mobileIndex === index,
                })}
              >
                <button
                  onClick={() => {
                    if (mobileIndex >= 0 && mobileIndex === index) {
                      setMbIndex(-1)
                    } else {
                      setMbIndex(index)
                    }
                    window.location.hash = `navi_3_${collection?.name
                      ?.toLowerCase()
                      .replace(/\s+/g, '_')}`
                  }}
                >
                  <span className="text-[13px] font-[500]">
                    {collection?.name}
                  </span>
                  <ChevronRightIcon
                    width={14}
                    className={cn(
                      s.collectionMenuDown,
                      'transition-all',
                      `${
                        mobileIndex === index
                          ? 'rotate-[90deg]'
                          : 'rotate-[0deg]'
                      }`
                    )}
                  />
                </button>

                <motion.div
                  className={s.optionContent}
                  animate={mobileIndex === index ? 'show' : 'hidden'}
                  variants={{
                    show: {
                      height: 'auto',
                      transition: {
                        duration: 0.3,
                        ease: easingTransitions?.easeInOutQuart,
                      },
                    },
                    hidden: {
                      height: 0,
                    },
                  }}
                >
                  <div className="w-full">
                    <div className="my-[20px] flex w-full flex-nowrap justify-start transition-all duration-300  ease-in-out l:gap-[12px] l:overflow-x-auto">
                      {collection?.bannerList?.map((product, index) => {
                        return (
                          <SceneCard
                            key={`starProducts-${product?.name}-${index}`}
                            index={index}
                            pageType={pageType}
                            product={product}
                          ></SceneCard>
                        )
                      })}
                    </div>
                  </div>
                  {currentDownBoxContent?.view_all_products && (
                    <div className="mx-auto mt-[25px] w-[172px]">
                      <SolixButton
                        className="h-[36px] leading-[36px] !text-[#fff]"
                        variant="link"
                        href={setRelativeLink({
                          link: collection?.href,
                        })}
                      >
                        {currentDownBoxContent?.view_all_products}
                      </SolixButton>
                    </div>
                  )}
                </motion.div>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default SceneBanner
