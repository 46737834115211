import { motion } from 'framer-motion'
import cn from 'classnames'
import { easingTransitions } from '@lib/utils/animate'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import { appendRefParameter, atobID, getProductTag } from '@lib/utils/tools'
import { Text, Picture } from '@components/ui'

function ProductCard({ product, index, pageType, has_custom, shopCommon }) {
  const variant = product?.variants?.[0]
  const url = appendRefParameter({
    url: has_custom
      ? product?.custom_href
      : `/products/${product?.handle}?variant=${atobID(variant?.id)}`,
    refValue: pageType === 'charger' ? 'naviMenu' : 'naviMenu_pps',
  })
  const name = has_custom
    ? product?.custom_title
    : variant?.metafields?.infos?.page_short_title ||
      product?.title ||
      product?.name

  const imageUrl = has_custom
    ? product?.custom_img
    : variant?.image?.url || product?.images?.[0]?.url

  const imageUrlMobile = has_custom ? product?.custom_mbImg : ''

  const tag = product.tags?.find(
    (tag) => tag in (shopCommon?.tagToDescription || {})
  )
  const tagTxt = getProductTag({
    variant: variant,
    custom: has_custom
      ? product?.custom_tag
      : variant?.metafields?.infos?.navTagTxt ||
        shopCommon?.tagToDescription?.[tag]?.label ||
        shopCommon?.tagToDescription?.[tag] ||
        '',
  })

  const { setRelativeLink } = useRelativeLink()

  return (
    <motion.a
      href={setRelativeLink({ link: url })}
      initial="initial"
      animate="animate"
      exit="exit"
      className="flex !h-[200px] !w-[180px] flex-shrink-0 flex-col items-center rounded-[10px] bg-white pb-[6px] md:py-[0px] l:!h-[280px] l:!w-[238px] l:!justify-center"
      variants={{
        initial: {
          opacity: 0,
          x: -20,
        },
        animate: {
          opacity: 1,
          x: 0,
          transition: {
            ease: easingTransitions?.easeInOutQuart,
            duration: 0.3,
            delay: 0.2 + index * 0.1,
          },
        },
        exit: {
          opacity: 0,
          transition: {
            ease: easingTransitions?.easeInQuart,
            duration: 0.3,
          },
        },
      }}
    >
      <div className="w-[115px] md:w-[180px]">
        <Picture
          customSize={200}
          source={`${imageUrl}, ${imageUrlMobile || imageUrl} 768`}
          className="aspect-h-[120] aspect-w-[120] w-full"
          imgClassName="w-full h-full object-cover"
        ></Picture>
      </div>
      <Text
        html={name}
        className="mt-[10px] line-clamp-3 w-[70%] text-center text-[14px] font-[500] leading-[16px] text-black md:w-full md:px-[12px]"
      ></Text>
      {tagTxt && (
        <Text
          className="mt-[4px] text-center text-[12px] font-[700] text-[#FF6B00] md:text-[14px]"
          html={tagTxt}
        ></Text>
      )}
    </motion.a>
  )
}

export default ProductCard
