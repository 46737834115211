import { easingTransitions } from '@lib/utils/animate'
import { motion } from 'framer-motion'
import { Text } from '@components/ui'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import cn from 'classnames'
import { useState } from 'react'
import { appendRefParameter } from '@lib/utils/tools'

const ItemList = ({ list, setRelativeLink, handleLiveChat, pageType }) => {
  return (
    <div className="mt-[40px] flex flex-col gap-[20px] md:mt-[12px] l:mb-[20px]">
      {list &&
        list.map((item, index) => {
          return (
            <div className="flex items-center gap-[4px] l:pl-[5px]" key={index}>
              <a
                className="!font-[500] leading-[20px] text-[#333]  hover:text-[var(--brand-color)] l:!w-auto l:!text-[12px]"
                href={setRelativeLink({
                  link: appendRefParameter({
                    url: item?.navLink,
                    refValue:
                      pageType === 'charger' ? 'naviMenu' : 'naviMenu_pps',
                  }),
                })}
                dangerouslySetInnerHTML={{
                  __html: item?.navName,
                }}
                onClick={(e) => {
                  if (item?.navLink === 'online-message') {
                    e?.preventDefault()
                    handleLiveChat(e)
                  }
                }}
              ></a>
              <ChevronRightIcon className="w-[12px] min-l:hidden" />
            </div>
          )
        })}
    </div>
  )
}

function NewExplore({ currentSmallBoxContent, handleLiveChat, s, pageType }) {
  const navList = currentSmallBoxContent?.navList || []
  const { setRelativeLink } = useRelativeLink()

  const [viewMore, setViewMore] = useState(0)

  const viewMoreHandler = (index) => {
    if (viewMore == index) {
      setViewMore(-1)
    } else {
      setViewMore(index)
    }
  }

  return (
    <motion.div
      key={`dropDownMiddleContent-${currentSmallBoxContent?.text}`}
      className="w-full min-l:py-[40px]"
      initial={{
        opacity: 0,
        x: -30,
      }}
      animate={{
        opacity: 1,
        x: 0,
        transition: {
          ease: easingTransitions?.easeInOutQuart,
          duration: 0.3,
          delay: 0.15,
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          ease: easingTransitions?.easeInQuart,
          duration: 0.3,
        },
      }}
    >
      <div className="mx-auto grid w-full max-w-[1280px] grid-flow-col gap-8  l:hidden">
        {navList &&
          navList?.map((navItem, index) => {
            return (
              <div key={index} className="">
                <Text
                  className="text-[14px] font-[600] leading-[28px] text-black"
                  html={navItem?.name}
                ></Text>
                {navItem?.list && (
                  <ItemList
                    setRelativeLink={setRelativeLink}
                    handleLiveChat={handleLiveChat}
                    list={navItem?.list}
                    pageType={pageType}
                  ></ItemList>
                )}
              </div>
            )
          })}
      </div>
      <div className="min-l:hidden">
        <ul className={s.menuMobile}>
          {navList?.map((collection, index) => {
            return (
              <li
                key={`collectionsMLi-${collection?.name}-${index}`}
                className={cn(s.option, ' pl-[5px] l:mb-[0px]')}
              >
                <button
                  onClick={() => {
                    viewMoreHandler(index)
                    window.location.hash = `navi_3_${collection?.name
                      ?.toLowerCase()
                      .replace(/\s+/g, '_')}`
                  }}
                >
                  <span className="text-[13px] font-[500] text-black">
                    {collection?.name}
                  </span>
                  <ChevronRightIcon
                    width={14}
                    className={cn(
                      s.collectionMenuDown,
                      'transition-all',
                      `${
                        viewMore === index ? 'rotate-[90deg]' : 'rotate-[0deg]'
                      }`
                    )}
                  />
                </button>

                <motion.div
                  className={s.optionContent}
                  animate={viewMore === index ? 'show' : 'hidden'}
                  variants={{
                    show: {
                      height: 'auto',
                      transition: {
                        duration: 0.3,
                        ease: easingTransitions?.easeInOutQuart,
                      },
                    },
                    hidden: {
                      height: 0,
                    },
                  }}
                >
                  <ItemList
                    setRelativeLink={setRelativeLink}
                    handleLiveChat={handleLiveChat}
                    list={collection?.list}
                    pageType={pageType}
                  ></ItemList>
                </motion.div>
              </li>
            )
          })}
        </ul>
      </div>
    </motion.div>
  )
}

export default NewExplore
